import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { Box, Typography, Button, Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemAvatar, Avatar, ListItemText, Icon, Menu, MenuItem, Link, } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Notification } from "./Notification";
import { useAppProvider } from "../AppContext";
import * as xverse from 'sats-connect'
import { WalletConnector, Network, SignPsbtOptions, ConnectedAccount, SendPsbtOptions, AddressItem, OKXWallet, UnisatWallet, XverseWallet } from '../types';
import { convertNetwort, formatNetwork, getAddressNetwork, getAddressType, sendToBackend } from '../utils';
import { base64, hex } from '@scure/base'
import * as bitcoin from 'bitcoinjs-lib'

const Connector = forwardRef<WalletConnector, {}>((props, ref) => {
  const {
    network,
    setNetwork,
    connectedAccount,
    setConnectedAccount,
    connectedWallet,
    setConnectedWallet,
  } = useAppProvider();

  useImperativeHandle(ref, (): WalletConnector => ({
    signPsbt,
    signPsbts,
    sendBitcoin,
    sendPsbts,
  }))

  const signPsbt = async (psbtHex: string, options?: SignPsbtOptions): Promise<string> => {
    if (!connectedWallet) {
      throw new Error('No wallet connected')
    }

    if (connectedWallet === UnisatWallet) {
      return window.unisat.signPsbt(psbtHex, options)
    }

    if (connectedWallet === XverseWallet) {
      const response = await xverse.request('signPsbt', {
        psbt: base64.encode(hex.decode(psbtHex)),
        signInputs: (options?.toSignInputs || []).reduce((acc, cur) => {
          let inputNums: number[] = acc[cur.address!] || []
          inputNums.push(cur.index)
          acc[cur.address!] = inputNums
          return acc
        }, {}),
        broadcast: true
      })
      if (response.status === 'success') {
        const psbt = bitcoin.Psbt.fromBase64(response.result.psbt)
        // if (options?.autoFinalized) {
        //   psbt.finalizeAllInputs()
        // }
        return psbt.toHex()
      } else {
        throw new Error(response.error.message)
      }
    }

    if (connectedWallet === OKXWallet) {
      return window.okxwallet.bitcoin.signPsbt(psbtHex, options)
    }

    throw new Error(`Unsupported wallet ${connectedWallet} for signing psbt`);
  }

  const signPsbts = async (psbtHexs: string[], options?: SignPsbtOptions[]): Promise<string[]> => {
    if (!connectedWallet) {
      throw new Error('No wallet connected')
    }

    if (connectedWallet === UnisatWallet) {
      return window.unisat.signPsbts(psbtHexs, options)
    }

    // TODO: implement xverse sign psbts


    if (connectedWallet === OKXWallet) {
      return window.okxwallet.bitcoin.signPsbts(psbtHexs, options)
    }

    throw new Error(`Unsupported wallet ${connectedWallet} for signing psbts`);
  }


  const sendBitcoin = async (toAddress: string, satoshis: number, options?: { feeRate?: number }): Promise<string> => {
    if (!connectedWallet) {
      throw new Error('No wallet connected')
    }

    if (connectedWallet === UnisatWallet) {
      return window.unisat.sendBitcoin(toAddress, satoshis, options)
    }

    if (connectedWallet === XverseWallet) {
      const response = await xverse.request('sendTransfer', {
        recipients: [
          {
            address: toAddress,
            amount: Number(satoshis)
          }
        ]
      })
      if (response.status === 'success') {
        return response.result.txid
      }
    }

    if (connectedWallet === OKXWallet && network === Network.Mainnet) {
      return window.okxwallet.bitcoin.sendBitcoin(toAddress, satoshis, options)
    }

    throw new Error(`Unsupported ${connectedWallet} wallet for sending bitcoin on ${network}`);
  }


  const sendPsbts = async (psbtHexs: string[], options?: SendPsbtOptions): Promise<(string | null)[]> => {
    if (!connectedWallet) {
      throw new Error('No wallet connected')
    }

    const _network = convertNetwort(network)

    const txids = await sendToBackend(
      psbtHexs.map(psbtHex => {
        const psbt = bitcoin.Psbt.fromHex(psbtHex, { network: _network })
        return psbt.extractTransaction().toHex()
      }),
      network
    )
    console.log(`sendToBackend result: ${JSON.stringify(txids)}`)

    if (connectedWallet === UnisatWallet) {
      const result = await Promise.allSettled(
        psbtHexs.map(async (psbtHex) => {
          try {
            const res = await window.unisat.pushPsbt(psbtHex)
            return res
          } catch (error) {
            console.log(`send psbt error : ${psbtHex}`, error)
            throw error
          }
        })
      )

      return result.map((res) => res.status === 'fulfilled' ? res.value : null);
    }

    if (connectedWallet === XverseWallet) {
      // xverse already sent it at the signing stage.
      return txids
    }

    // TODO: implement okx send psbts

    throw new Error(`Method #sendPsbts not implemented for ${connectedWallet}`);
  }

  const [_openDialog, setOpenDialog] = useState(false)

  const [_notificationSeverity, setNotificationSeverity] = useState<'info' | 'error' | 'warning' | 'success'>('info');
  const [_notificationMsg, setNotificationMsg] = useState<string | undefined>();

  const onDialogClose = () => {
    setOpenDialog(false)
  }

  const [_installedWallets, setInstalledWallets] = useState<string[]>([])
  const detectInstalledWallets = async () => {
    const installedWallets: string[] = []

    // detect unisat
    if (typeof window.unisat !== 'undefined') {
      installedWallets.push(UnisatWallet)
    }

    // detect xverse
    try {
      const xverseWallet = await xverse.request('getInfo', null)
      if (xverseWallet.status === 'success') {
        installedWallets.push(XverseWallet)
      }
    } catch (e) { }

    // detect okx
    if (typeof window.okxwallet !== 'undefined') {
      installedWallets.push(OKXWallet)
    }

    setInstalledWallets(installedWallets)

    console.log('installed wallets', installedWallets)
    return installedWallets
  }

  const detectConnectedWallet = async (installedWallets: string[]) => {
    setConnectedWallet(undefined)
    setConnectedAccount(undefined)

    // detect unisat connection
    if (installedWallets.includes(UnisatWallet)) {
      try {
        const accounts = await window.unisat.getAccounts()
        onUnisatAccountsChanged(accounts)
      } catch (e) { }
    }

    // Note: xverse can not be detected due to its `getAccounts` would pop up a dialog
    // if (installedWallets.includes(XverseWallet)) {
    //   try {
    //     const response = await xverse.request('getAccounts', { purposes: [xverse.AddressPurpose.Payment]})
    //     if (response.status === 'success') {
    //       setConnectedWallet(XverseWallet)
    //       setConnectedAddress(response.data[0].address)
    //       console.log('connected xverse accounts', response)
    //     }
    //   } catch (e) { }
    // }

    // detect okx connection
    if (installedWallets.includes(OKXWallet) && network === Network.Mainnet) {
      try {
        const accounts: string[] = await window.okxwallet.bitcoin.getAccounts()
        const pubkey = await window.okxwallet.bitcoin.getPublicKey()
        if (accounts[0] && pubkey) {
          onOKXAccountChanged({
            address: accounts[0],
            publicKey: pubkey
          })
        }
      } catch (e) {
        console.log('connected okx accounts err', e)
      }
    }
  }

  useEffect(() => {
    const handler = () => {
      detectInstalledWallets()
        .then((installedWallets) => {
          return detectConnectedWallet(installedWallets)
        })
    }

    window.addEventListener('load', handler)

    return () => {
      window.removeEventListener('load', handler)

      if (_installedWallets.includes(UnisatWallet)) {
        window.unisat.removeListener('networkChanged', onNetworkChanged)
        window.unisat.removeListener('accountsChanged', onUnisatAccountsChanged)
      }

      if (_installedWallets.includes(OKXWallet)) {
        window.okxwallet.bitcoin.removeListener('accountChanged', onOKXAccountChanged)
      }

    }
  }, [])

  const checkWalletInstallation = (walletName: string) => {
    if (!_installedWallets.includes(walletName)) {
      setNotificationMsg(`${walletName} Wallet not found. Please install it first.`)
      setNotificationSeverity('error')
      return false
    }
    return true
  }

  const [_connectingWallet, setConnectingWallet] = useState<string | undefined>();

  const onNetworkChanged = (network: string) => {
    setNetwork(formatNetwork(network))
  }

  const onUnisatAccountsChanged = async (accounts: string[]) => {
    if (accounts.length > 0) {
      // update network according to the wallet
      const walletNetwork = formatNetwork(await window.unisat.getNetwork())
      const pubkey = await window.unisat.getPublicKey();
      if (pubkey) {
        setNetwork(walletNetwork)
        setConnectedWallet(UnisatWallet)
        setConnectedAccount({
          payment: {
            address: accounts[0],
            addressType: getAddressType(accounts[0], walletNetwork),
            publicKey: pubkey,
          }
        })
        window.unisat.on('networkChanged', onNetworkChanged)
        window.unisat.on('accountsChanged', onUnisatAccountsChanged)
      }
    } else {
      setConnectedWallet(undefined)
      setConnectedAccount(undefined)
    }
  }

  const onOKXAccountChanged = async (account?: { 'address': string, 'publicKey': string }) => {
    if (account) {
      const walletNetwork = formatNetwork(await window.okxwallet.bitcoin.getNetwork())
      setNetwork(walletNetwork)
      setConnectedWallet(OKXWallet)
      setConnectedAccount({
        payment: {
          address: account.address,
          addressType: getAddressType(account.address, walletNetwork),
          publicKey: account.publicKey,
        }
      })
      window.okxwallet.bitcoin.on('accountChanged', onOKXAccountChanged)
    } else {
      setConnectedWallet(undefined)
      setConnectedAccount(undefined)
    }
  }

  const onConnectUnisat = async () => {
    if (!checkWalletInstallation(UnisatWallet)) {
      return
    }
    setOpenDialog(false)
    setConnectingWallet(UnisatWallet)

    try {
      const accounts = await window.unisat.requestAccounts()
      onUnisatAccountsChanged(accounts)
    } catch (e: any) {
      setNotificationMsg(`Connect Error: ${e.message}`)
      setNotificationSeverity('error')
    }

    setConnectingWallet(undefined)
  }

  const onConnectXverse = async () => {
    if (!checkWalletInstallation(XverseWallet)) {
      return
    }
    setOpenDialog(false)
    setConnectingWallet(XverseWallet)

    try {
      const response = await xverse.request('getAccounts', { purposes: [xverse.AddressPurpose.Payment, xverse.AddressPurpose.Ordinals] })
      if (response.status === 'success') {
        const paymentAddressItem = response.result.find((acc: any) => acc.purpose === xverse.AddressPurpose.Payment)
        const orinalsAddressItem = response.result.find((acc: any) => acc.purpose === xverse.AddressPurpose.Ordinals)
        const walletNetwork = getAddressNetwork(paymentAddressItem!.address)
        setNetwork(walletNetwork)
        setConnectedWallet(XverseWallet)
        setConnectedAccount({
          payment: {
            address: paymentAddressItem!.address,
            addressType: paymentAddressItem!.addressType || getAddressType(paymentAddressItem!.address, network),
            publicKey: paymentAddressItem!.publicKey,
          },
          ordinals: {
            address: orinalsAddressItem!.address,
            addressType: orinalsAddressItem!.addressType || getAddressType(orinalsAddressItem!.address, network),
            publicKey: orinalsAddressItem!.publicKey,
          }
        })
      } else {
        throw new Error(response.error.message)
      }
    } catch (e: any) {
      setNotificationMsg(`Connect Error: ${e.message || e.error.message}`)
      setNotificationSeverity('error')
    }
    setConnectingWallet(undefined)
  }

  const onConnectOKX = async () => {
    if (!checkWalletInstallation(OKXWallet)) {
      return
    }
    setOpenDialog(false)
    setConnectingWallet(OKXWallet)

    try {
      if (network === Network.Testnet) {
        throw new Error('OKX Wallet does not support Testnet')
      }

      const result = await window.okxwallet.bitcoin.connect()
      onOKXAccountChanged(
        (result?.address && result?.publicKey)
          ? {
            address: result.address,
            publicKey: result.publicKey
          }
          : undefined
      )
    } catch (e: any) {
      setNotificationMsg(`Connect Error: ${e.message}`)
      setNotificationSeverity('error')
    }
    setConnectingWallet(undefined)
  }

  const [_menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const disconnect = () => {
    setConnectedWallet(undefined)
    setConnectedAccount(undefined)
  }

  const condenseAddress = (account: ConnectedAccount) => {
    const address = account.payment.address
    return `${address.slice(0, 6)}...${address.slice(-6)}`
  }

  return (
    <Box>
      {
        connectedWallet && connectedAccount ?
          <Box>
            <Button
              variant='outlined'
              startIcon={
                <Avatar
                  variant="rounded"
                  alt="wallet logo"
                  src={`/${connectedWallet.toLowerCase()}.svg`}
                  sx={{ mr: 1, width: 20, height: 20 }}
                />
              }
              onClick={(e) => {
                setMenuAnchorEl(e.currentTarget)
              }}
            >
              {condenseAddress(connectedAccount)}
            </Button>
            <Menu
              anchorEl={_menuAnchorEl}
              open={Boolean(_menuAnchorEl)}
              onClose={() => {
                setMenuAnchorEl(null)
              }}
              sx={{ zIndex: 1200 }}
            >
              <MenuItem
                onClick={() => {
                  setMenuAnchorEl(null)
                  disconnect()
                }}
                sx={{ color: '#FE9C2F' }}
              >
                <LinkOffIcon />
                <Link
                  component="button"
                  underline='none'
                  sx={{ pl: 2 }}
                >
                  Disconnect Wallet
                </Link>
              </MenuItem>
            </Menu>
          </Box>
          :
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AccountBalanceWalletIcon />}
            onClick={() => setOpenDialog(true)}
          >
            Connect
          </Button>
      }

      <Dialog
        onClose={onDialogClose}
        open={_openDialog}
      >
        <DialogTitle sx={{ textAlign: 'center', mb: 2 }}>Connect Wallet</DialogTitle>
        <List sx={{}}>
          <ListItem >
            <ListItemButton
              autoFocus
              style={{ border: '1px solid #999', borderRadius: '3px' }}
              sx={{ pt: 3, pb: 3, pl: 5, pr: 5 }}
              onClick={onConnectUnisat}
              disabled={!_installedWallets.includes(UnisatWallet) || _connectingWallet === UnisatWallet}
            >
              <Avatar variant="square" alt="unisat wallet logo" src="/unisat.svg" sx={{ mr: 2 }} />
              <Typography variant="h3">Unisat Wallet</Typography>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton
              autoFocus
              style={{ border: '1px solid #999', borderRadius: '3px' }}
              sx={{ pt: 3, pb: 3, pl: 5, pr: 5 }}
              onClick={onConnectXverse}
              disabled={!_installedWallets.includes(XverseWallet) || _connectingWallet === XverseWallet}
            >
              <Avatar variant="square" alt="xverse wallet logo" src="/xverse.svg" sx={{ mr: 2 }} />
              <Typography variant="h3">Xverse Wallet</Typography>
            </ListItemButton>

          </ListItem>
          {
            network === Network.Mainnet &&
            (
              <ListItem>
                <ListItemButton
                  autoFocus
                  style={{ border: '1px solid #999', borderRadius: '3px' }}
                  sx={{ pt: 3, pb: 3, pl: 5, pr: 5 }}
                  onClick={onConnectOKX}
                  disabled={!_installedWallets.includes(OKXWallet) || _connectingWallet === OKXWallet}
                >
                  <Avatar variant="square" alt="okx wallet logo" src="/okx.svg" sx={{ mr: 2 }} />
                  <Typography variant="h3">OKX Wallet</Typography>
                </ListItemButton>
              </ListItem>
            )
          }
        </List>
      </Dialog>
      <Notification message={_notificationMsg} setMessage={setNotificationMsg} severity={_notificationSeverity} />
    </Box>

  )
})

export default Connector;