import React from 'react';
import { Alert, Snackbar } from '@mui/material';

interface NotificationProps {
  message?: string;
  setMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  severity?: 'error' | 'warning' | 'info' | 'success';
  duration?: number;
}

export const Notification = (props: NotificationProps) => {

  const handleClose = (e) => {
    props.setMessage(undefined)
  }

  return (
    <Snackbar
      open={props.message !== undefined}
      autoHideDuration={props.duration || 5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ zIndex: 1200 }}
    >
      <Alert
        onClose={handleClose}
        severity={props.severity || 'info'}
        variant="filled"
        sx={{ width: '100%' }}
      >
        {props.message}
      </Alert>
    </Snackbar>
  )
}
