import { AddressType } from "sats-connect";

export enum Network {
  Testnet = 'testnet',
  Mainnet = 'mainnet',
}

export const UnisatWallet = 'unisat'
export const XverseWallet = 'xverse'
export const OKXWallet = 'okx'

export interface Rune {
  name: string;
  divisibility?: number;
  premine?: number;
  symbol?: string;
  amount: number;
  cap: number;
  startHeight?: number;
  endHeight?: number;
  startOffset?: number;
  endOffset?: number;
  dataURL?: string;
}

export interface SignPsbtOptions {
  autoFinalized: boolean;
  toSignInputs: Array<{
    index: number;
    address?: string;
    publicKey?: string;
    sighashTypes?: number[];
  }>;
}



export interface SendPsbtOptions {
  network: Network;
}

export interface AddressItem {
  address: string;
  addressType: AddressType;
  publicKey: string;
}

export interface ConnectedAccount {
  payment: AddressItem,
  ordinals?: AddressItem,
}

export interface WalletConnector {
  signPsbt: (psbtHex: string, options?: SignPsbtOptions) => Promise<string>;
  signPsbts: (psbtHexs: string[], options?: SignPsbtOptions[]) => Promise<string[]>;
  sendBitcoin: (toAddress: string, satoshis: number, options?: { feeRate?: number }) => Promise<string>;
  sendPsbts: (psbtHexs: string[], options?: SendPsbtOptions) => Promise<(Array<string | null>)>;
}
