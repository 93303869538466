import React, { useEffect, useState } from "react";
import { HistoryEntry, OrderStatus } from "./dtos/history.dto";
import {
  Container,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
} from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import { useAppProvider } from "./AppContext";
import { Network } from "./types";
import { theme } from "./theme";
import { StorageAPI } from "./storage";
import { UtxoDto } from "./dtos/utxo.dto";
import {
  queryOrderInfo,
} from "./utils";

function History() {
  const { network } = useAppProvider();

  const itemsPerPage = 5;
  const [page, setPage] = useState(1); // Current page
  const [numPages, setNumPages] = useState(0); // Total number of pages

  const [history, setHistory] = useState<HistoryEntry[]>([]);

  const updateHistory = async () => {
    const fetched = StorageAPI.getHistoryFull(network);

    for (const entry of fetched) {
      if (
        entry.orderStatus !== OrderStatus.FAILED &&
        entry.orderStatus !== OrderStatus.SUCCEEDED
      ) {
        try {
          const orderInfo = await queryOrderInfo(entry.orderId, network);

          if (orderInfo) {
            if (entry.orderStatus !== orderInfo.status) {
              StorageAPI.setOrderStatus(
                entry.orderId,
                orderInfo.status as OrderStatus,
                network
              );
            }

            if (entry.action === 3) {
              const { details } = orderInfo;
              const { txs } = details;

              const confirmed = txs.reduce((acc, tx) => {
                if (tx.status === "confirmed") {
                  acc++;
                }
                return acc;
              }, 0);

              const sent = txs.reduce((acc, tx) => {
                if (tx.status === "confirmed" || tx.status === "unconfirmed") {
                  acc++;
                }
                return acc;
              }, 0);

              StorageAPI.setBatchMintStatus(
                entry.orderId,
                {
                  confirmed,
                  sent,
                  repeat: entry.batchMintStatus!.repeat,
                },
                network
              );
            } else  if (entry.action === 0 || entry.action === 1) {
              // If etch reveal tx was confirmed, also fetch belonging rune ID.
              if(orderInfo.status === OrderStatus.SUCCEEDED) {

                const { details } = orderInfo;
                const { id } = details;

                if(id) {
                  StorageAPI.setRuneId(entry.orderId, id, network);
                }

              }

            }
          } else {
            console.error("Error queryOrderInfo ", entry.orderId);
          }
        } catch (error) {
          console.error("Error fetching UTXO data:", error);
        }
      }

    }
  };

  useEffect(() => {
    setHistory(StorageAPI.getHistoryFull(network));

    updateHistory().then(() => {
      setHistory(StorageAPI.getHistoryFull(network));
    });
  }, []);

  useEffect(() => {
    setNumPages(Math.ceil(history.length / itemsPerPage));
  }, [history]);

  const getStatusLabel = (entry: HistoryEntry): string => {

    if(entry.action === 3) {
      return `${entry.orderStatus ? entry.orderStatus : ""}(${entry.batchMintStatus?.confirmed!}/${entry.batchMintStatus?.repeat!})`;
    }

    if(entry.orderStatus) {
      return entry.orderStatus;
    }


    // Compatible with old data
    if (!entry.utxo.status || !entry.utxo.status.confirmed || entry.action === 0) {
      return 'Pending...'
    }
    return 'Complete'

  };

  const getActionLabel = (action: number): string => {
    const actionMap = { 0: "Etch", 1: "Etch", 2: "Mint", 3: "Batch Mint" };
    return actionMap[action] || "Unknown";
  };

  const formatDate = (timestamp: number): string => {
    return new Date(timestamp * 1000).toLocaleString();
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const getEntryURL = (entry: HistoryEntry) => {
    const prefix = `https://${
      network === Network.Testnet ? "testnet." : ""
    }ordinals.com`;



    if (entry.action === 1 || entry.action === 0) {
      if (entry.orderStatus !== OrderStatus.SUCCEEDED) {
        return `${prefix}/tx/${entry.utxo.txid}`;
      }

      return `${prefix}/rune/${entry.runeName}`;
    } else {
      return `${prefix}/tx/${entry.utxo.txid}`;
    }
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = history.slice(startIndex, endIndex);

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 6 }}>
        {history.length > 0 ? (
          currentItems.map((entry, index) => (
            <Card
              key={index}
              sx={{
                mb: 6,
                backgroundColor: theme.palette.card.default,
              }}
            >
              <CardContent
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 4,
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "bold", fontSize: "1.5rem", mb: 4 }}
                  >
                    {entry.runeName}
                  </Typography>
                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    {entry.orderId ? (
                      <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "bold", mb: 2 }}
                        >
                          Order ID:{" "}
                        </Typography>
                        <Typography variant="body1" sx={{ marginLeft: 1 }}>
                          {entry.orderId}
                        </Typography>
                      </Grid>
                    ) : (
                      <></>
                    )}

                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mb: 2 }}
                      >
                        ID:
                      </Typography>
                      <Typography variant="body1">
                        {entry.runeId || "Pending..."}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mb: 2 }}
                      >
                        Status:
                      </Typography>
                      <Typography variant="body1">
                          {getStatusLabel(entry)}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Typography
                        variant="body1"
                        sx={{ fontWeight: "bold", mb: 2 }}
                      >
                        Action:
                      </Typography>
                      <Typography variant="body1">
                        {getActionLabel(entry.action)}
                      </Typography>
                      {entry.action === 2 ? (
                        <div>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                          >
                            Amount:
                          </Typography>
                          <Typography variant="body1">
                            {entry.amount}
                          </Typography>
                        </div>
                      ) : (
                        <></>
                      )}

                      {entry.action === 3 ? (
                        <div>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "bold", mt: 2, mb: 2 }}
                          >
                            Amount:
                          </Typography>
                          <Typography variant="body1">
                            {entry.amount * entry.batchMintStatus?.repeat!}
                          </Typography>
                        </div>
                      ) : (
                        <></>
                      )}
                    </Grid>
                  </Grid>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ mt: entry.action === 2 ? 2 : 4 }}
                  >
                    {formatDate(entry.dateTime)}
                  </Typography>
                </Box>
                <Button
                  size="small"
                  color="primary"
                  sx={{ minWidth: "68px", height: "68px" }}
                  onClick={() => window.open(getEntryURL(entry), "_blank")}
                >
                  <LinkIcon />
                </Button>
              </CardContent>
            </Card>
          ))
        ) : (
          <div></div>
        )}
        {/* Pagination controls */}
        {history.length > 0 && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              disabled={page === 1}
              onClick={() => handleChangePage(page - 1)}
            >
              Previous
            </Button>
            {Array.from({ length: numPages }, (_, i) => (
              <Button
                key={i + 1}
                onClick={() => handleChangePage(i + 1)}
                sx={{ mx: 1, fontWeight: page === i + 1 ? "bold" : "normal" }}
              >
                {i + 1}
              </Button>
            ))}
            <Button
              disabled={page === numPages}
              onClick={() => handleChangePage(page + 1)}
            >
              Next
            </Button>
          </Box>
        )}
      </Box>
    </Container>
  );
}

export default History;
