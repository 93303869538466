import axios from 'axios';
import { AxiosResponse } from 'axios';
import { Network } from './types';

import * as bitcoin from 'bitcoinjs-lib';
import * as btc from 'micro-btc-signer'
import { hex, base64 } from '@scure/base'

import { UtxoDto } from './dtos/utxo.dto';
import { GetUtxosResponseDto } from './dtos/getUtxosResponse.dto';
import { AddressType } from 'sats-connect';
import {
  Signer,
  initEccLib,
  crypto
} from "bitcoinjs-lib";

import ecc from "@bitcoinerlab/secp256k1";
import ECPairFactory, { ECPairAPI } from 'ecpair';
import { GetRuneResponseDto } from './dtos/getRuneResponse.dto';
import { Rune } from 'runelib';
import { OrderStatus } from './dtos/history.dto';

initEccLib(ecc as any);
const ECPair: ECPairAPI = ECPairFactory(ecc);

const SATS_SCACLE = 10 ** 8

export function sats2Btc(sats: number): number {
  return sats / SATS_SCACLE;
}

export function sats2Usd(sats: number, btcInUSD: number): number {
  const usd = sats * btcInUSD / SATS_SCACLE
  return Math.ceil(usd * 100) / 100;
}

export function getBackendServiceUrl(network: Network): string {
  network.toString()
  const BACKEND_SERVICE_URL = process.env[`REACT_APP_${network.toUpperCase()}_BACKEND_SERVICE_URL`]
  if (!BACKEND_SERVICE_URL) throw new Error(`REACT_APP_${network.toUpperCase()}_BACKEND_SERVICE_URL should be set in env`);
  return BACKEND_SERVICE_URL.replace(/\/$/, "");
}

export function getOrderServiceUrl(network: Network): string {
  network.toString()
  const ORDER_SERVICE_URL = process.env[`REACT_APP_${network.toUpperCase()}_ORDER_SERVICE_URL`]
  if (!ORDER_SERVICE_URL) throw new Error(`REACT_APP_${network.toUpperCase()}_ORDER_SERVICE_URL should be set in env`);
  return ORDER_SERVICE_URL.replace(/\/$/, "");
}

export function getIndexerServiceUrl(network: Network): string {
  const INDEXER_SERVICE_URL = process.env[`REACT_APP_${network.toUpperCase()}_INDEXER_SERVICE_URL`]
  if (!INDEXER_SERVICE_URL) throw new Error(`REACT_APP_${network.toUpperCase()}_INDEXER_SERVICE_URL should be set in env`);
  return INDEXER_SERVICE_URL.replace(/\/$/, "");
}


export function getExplorerServiceUrl(network: Network): string {
  const EXPLORER_SERVICE_URL = process.env[`REACT_APP_${network.toUpperCase()}_EXPLORER_SERVICE_URL`]
  if (!EXPLORER_SERVICE_URL) throw new Error(`REACT_APP_${network.toUpperCase()}_EXPLORER_SERVICE_URL should be set in env`);
  return EXPLORER_SERVICE_URL.replace(/\/$/, "");
}


export interface ServiceFee {
  address: string;
  fee: number;
}

export const DEFAULT_SERVICE_FEE = 10000
export const MINT_TX_EST_SIZE = 185
export const ETCH_COMMINT_TX_EST_SIZE = 250
export const ETCH_REVEAL_TX_EST_SIZE = 300
export const POSTAGE_SATS = 546

export function getServiceFee(network: Network): Promise<ServiceFee> {
  const address = process.env[`REACT_APP_${network.toUpperCase()}_SERVICE_FEE_ADDRESS`]
  if (!address) throw new Error(`REACT_APP_${network.toUpperCase()}_SERVICE_FEE_ADDRESS should be set in env`);
  const fee = parseInt(process.env[`REACT_APP_${network.toUpperCase()}_SERVICE_FEE`] || DEFAULT_SERVICE_FEE.toString())
  return Promise.resolve({
    address,
    fee,
  })
}


export function getAPIkey(): string {
  const apikey = process.env[`REACT_APP_OPEN_API_KEY`]
  if (!apikey) throw new Error(`REACT_APP_OPEN_API_KEY should be set in env`);
  return apikey
}

export function formatNetwork(network: string): Network {
  const _network = network.toLowerCase()
  if (_network === 'mainnet' || _network === 'livenet') return Network.Mainnet
  if (_network === 'testnet') return Network.Testnet
  throw new Error(`Invalid network: ${network}`)
}

export function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2); // getMonth 返回的月份从 0 开始，所以需要 +1
  const day = ("0" + date.getDate()).slice(-2);
  const hours = ("0" + date.getHours()).slice(-2);
  const minutes = ("0" + date.getMinutes()).slice(-2);
  const seconds = ("0" + date.getSeconds()).slice(-2);

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export function isValidBTCAddress(address: string, network: Network = Network.Mainnet): boolean {
  try {
    bitcoin.address.toOutputScript(address, convertNetwort(network));
    return true;
  } catch (e) {
    return false;
  }
}

export function getAddressType(address: string, network: Network): AddressType {
  const _network = convertNetwort(network)
  try {
    const decoded = bitcoin.address.fromBase58Check(address);
    if (decoded.version === _network.pubKeyHash) {
      return AddressType.p2pkh;
    } else if (decoded.version === _network.scriptHash) {
      return AddressType.p2sh;
    }
  } catch (e) {
    try {
      const decoded = bitcoin.address.fromBech32(address);
      if (decoded.prefix === 'bc' || decoded.prefix === 'tb') {
        if (decoded.version === 0) {
          if (decoded.data.length === 20) {
            return AddressType.p2wpkh;
          } else if (decoded.data.length === 32) {
            return AddressType.p2wsh;
          }
        } else if (decoded.version === 1) {
          return AddressType.p2tr;
        }
      }
    } catch (e) {
      throw new Error('Invalid address');
    }
  }
  throw new Error('Unknown address type');
}

export function getP2WPKHInput(utxo: UtxoDto, address: string, network: Network) {
  const _network = convertNetwort(network)
  return {
    hash: utxo.txid,
    index: utxo.vout,
    witnessUtxo: { value: utxo.value, script: bitcoin.address.toOutputScript(address, _network) },
  }
}

export function getP2SHInput(utxo: UtxoDto, publicKey: string, network: Network) {
  const _network = convertNetwort(network)
  const pubkey = hex.decode(publicKey)
  const p2wpkh = btc.p2wpkh(pubkey, _network)
  const p2sh = btc.p2sh(p2wpkh, _network)
  return {
    hash: utxo.txid,
    index: utxo.vout,
    witnessUtxo: {
      script: Buffer.from(p2sh.script),
      value: utxo.value,
    },
    redeemScript: Buffer.from(p2sh.redeemScript!),
  }
}

export function getP2TRInput(utxo: UtxoDto, address: string, publicKey: string, network: Network) {
  const _network = convertNetwort(network);

  return {
    hash: utxo.txid,
    index: utxo.vout,
    witnessUtxo: { value: utxo.value, script: bitcoin.address.toOutputScript(address, _network) },
    tapInternalKey: toXOnly(Buffer.from(publicKey, 'hex'))
  }
}


export function convertNetwort(network: Network): bitcoin.networks.Network {
  return network === Network.Mainnet ? bitcoin.networks.bitcoin : bitcoin.networks.testnet;
}

export function tapTweakHash(pubKey: Buffer, h: Buffer | undefined): Buffer {
  return crypto.taggedHash(
    "TapTweak",
    Buffer.concat(h ? [pubKey, h] : [pubKey])
  );
}

export function toXOnly(pubkey: Buffer): Buffer {
  if (pubkey.length === 32) {
    return pubkey; // already x-only
  }
  return pubkey.subarray(1, 33);
}

export function tweakSigner(signer: Signer, opts: any = {}): Signer {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let privateKey: Uint8Array | undefined = signer.privateKey!;
  if (!privateKey) {
    throw new Error("Private key is required for tweaking signer!");
  }
  if (signer.publicKey[0] === 3) {
    privateKey = ecc.privateNegate(privateKey);
  }

  const tweakedPrivateKey = ecc.privateAdd(
    privateKey,
    tapTweakHash(toXOnly(signer.publicKey), opts.tweakHash)
  );
  if (!tweakedPrivateKey) {
    throw new Error("Invalid tweaked private key!");
  }

  return ECPair.fromPrivateKey(Buffer.from(tweakedPrivateKey), {
    network: opts.network,
  });
}




export async function waitUntilUTXO(address: string, network: Network) {

  const blockstream = new axios.Axios({
    baseURL: `https://blockstream.info/testnet/api`
  });


  return new Promise<IUTXO[]>((resolve, reject) => {
    let intervalId: any;
    const checkForUtxo = async () => {
      try {
        const response: AxiosResponse<string> = await blockstream.get(`/address/${address}/utxo`);
        const data: IUTXO[] = response.data ? JSON.parse(response.data) : undefined;
        console.log(data);
        if (data.length > 0) {
          resolve(data);
          clearInterval(intervalId);
        }
      } catch (error) {
        // reject(error);
        // clearInterval(intervalId);
      }
    };
    intervalId = setInterval(checkForUtxo, 10000);
  });
}

export const selectUtxos = (estFee: number, paymentUtxos: UtxoDto[], address: string): UtxoDto[] => {
  const sortedUtxos = paymentUtxos.sort((a, b) => a.value - b.value)
  const selectedUtxos: UtxoDto[] = []
  let total = 0
  for (const utxo of sortedUtxos) {
    total += utxo.value
    selectedUtxos.push(utxo)
    if (total > estFee) {
      break
    }
  }
  if (total < estFee) {
    throw new Error(`Insufficient balance of the address ${address} to pay the fee ${estFee} satoshis. Total balance: ${total} satoshis`)
  }
  return selectedUtxos
}

interface IUTXO {
  txid: string;
  vout: number;
  status: {
    confirmed: boolean;
    block_height: number;
    block_hash: string;
    block_time: number;
  };
  value: number;
}


export const sendToBackend = async (txs: string[], network: Network): Promise<(string | null)[]> => {

  const result = await Promise.allSettled(
    txs.map(async (txHex) => {
      try {
        console.log('txhex', txHex)
        const response: AxiosResponse<{
          code: number,
          txid: string,
          error: string
        }> = await axios.post(`${getBackendServiceUrl(network)}/runes/tx/${network}/broadcast`, {
          raw: txHex
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        if (response.data.code === 0) {
          return response.data.txid
        }
        throw new Error(`Failed to sendToBackend: ${JSON.stringify(response.data)}`)
      } catch (error) {
        console.log(`sendToBackend error: `, error, txHex)
        throw error
      }
    })
  )

  return result.map((res) => res.status === 'fulfilled' ? res.value : null);
}


export const calcTotalSize = (repeat: number, mintstoneScriptSize: number) => {

  const POSTAGE_OUT_SIZE = 8 + 1 + 22;

  const SPLIT_OUT_SIZE = 8 + 1 + 22;

  const SERVCE_OUT_SIZE = 8 + 1 + 22;

  const OP_RETURN_OUT_SIZE = 8 + 1 + mintstoneScriptSize;

  const outCounter = (repeat - 1) + 3;
  const splitTxSize =
    4  // version
    + 2 // Flag
    + 1 // In-counter
    + 32 // Outpoint hash of input
    + 4 // Outpoint index
    + 1 // Script length
    + 0 // Script signature
    + 4 // Sequence number
    + varintLen(outCounter) // Out-counter size
    + OP_RETURN_OUT_SIZE
    + POSTAGE_OUT_SIZE
    + SPLIT_OUT_SIZE * (repeat - 1)
    + SERVCE_OUT_SIZE
    + 73 // signature in Witnesses
    + 33 // public key in Witnesses
    + 4 // lock_time

  const mintTxSize =
    4  // version
    + 2 // Flag
    + 1 // In-counter
    + 32 // Outpoint hash of input
    + 4 // Outpoint index
    + 1 // Script length
    + 0 // Script signature
    + 4 // Sequence number
    + varintLen(2) // Out-counter size
    + OP_RETURN_OUT_SIZE
    + POSTAGE_OUT_SIZE
    + 73 // signature in Witnesses
    + 33 // public key in Witnesses
    + 4 // lock_time


  return {
    totalSize: (mintTxSize * (repeat - 1) + splitTxSize),
    mintTxSize,
    splitTxSize,
  }
}





export const isRuneExist = async (runename: string, network: Network): Promise<GetRuneResponseDto | null | false> => {

  return axios.get<any, AxiosResponse<GetRuneResponseDto, any>>(
    `${getIndexerServiceUrl(network)}/rune/${runename}`,
    {
      headers: {
        'Accept': 'application/json',
      }
    }
  )
    .then((resp) => {
      if (resp.status === 200) {
        return resp.data;
      }

      return false;
    })
    .catch(e => {
      console.error('queryToRunename failed:', e)
      return null;
    })

}

export const MINIMUM_RUNE_FOR_NEXT_BLOCK = "AAAAAAAAAAAAA"

export const getMinimumRune = async (network: Network): Promise<string> => {
  return await axios.get<any, AxiosResponse<any, any>>(
    `${getIndexerServiceUrl(network)}/status`,
    {
      headers: {
        'Accept': 'application/json',
      }
    }
  )
    .then((resp) => {

      if (resp.status === 200) {
        return resp.data?.minimum_rune_for_next_block || MINIMUM_RUNE_FOR_NEXT_BLOCK;
      }

      return MINIMUM_RUNE_FOR_NEXT_BLOCK;
    })
    .catch(e => {
      return MINIMUM_RUNE_FOR_NEXT_BLOCK;
    })


}

export const isValidRune = (runename: string, minimum: string): boolean => {
  if (typeof runename !== 'string') {
    return false;
  }
  const rune = Rune.fromName(runename);
  const rune_minimum = Rune.fromName(minimum);

  return rune.value >= rune_minimum.value;
}


export function onlyCapitalLetters(str) {
  return str.replace(/[^A-Z•]+/g, "");
}

export function getAddressNetwork(address: string): Network {
  if (
    address.startsWith('1')   // p2pkh
    || address.startsWith('3') // p2sh
    || address.startsWith('bc1') // bech32: p2wpkh / p2tr
  ) {
    return Network.Mainnet
  }

  if (
    address.startsWith('m') || address.startsWith('n') // p2pkh
    || address.startsWith('2') // p2sh
    || address.startsWith('tb1')  // bech32: p2wpkh / p2tr
  ) {
    return Network.Testnet
  }

  throw new Error(`Network unknown for address: ${address}`)
}

export function varintLen(n: number) {

  if (n < 0xFD) {
    return 1;
  } else if (n <= 0xFFFF) {
    return 3;
  } else if (n <= 0xFFFFFFFF) {
    return 5;
  } else {
    return 9;
  }
}


export function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const createMintOrder = async (json: any, network: Network): Promise<{
  orderId: string,
  payAddress: string,
  payAmount: number
}> => {

  console.log('network:', network)
  const response: AxiosResponse<{
    statusCode: number,
    data: {
      orderId: string,
      payAddress: string,
      payAmount: number
    }
  }> = await axios.post(`${getOrderServiceUrl(network)}/btc/${network}/orders/runes/mint/create`, json, {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${getAPIkey()}`
    }
  });

  if (response.data.statusCode === 0) {
    return response.data.data
  }

  throw new Error(`Failed to create mint order: ${response.data ? JSON.stringify(response.data) : "Unknow error."}`)
}



export const payMintOrder = async (json: any, network: Network): Promise<{
  txs: Array<{
    txId: string,
    status: string,
  }>,
  status: string
}> => {


  const response: AxiosResponse<{
    statusCode: number,
    data: {
      txs: Array<{
        txId: string,
        status: string,
      }>,
      status: string
    }
  }> = await axios.post(`${getOrderServiceUrl(network)}/btc/${network}/orders/runes/mint/pay`, json, {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${getAPIkey()}`
    }
  });

  if (response.data.statusCode === 0) {
    return response.data.data
  }

  throw new Error(`Failed to pay mint order: ${response.data ? JSON.stringify(response.data) : "Unknow error."}`)
}



export const createEtchOrder = async (json: any, network: Network): Promise<{
  orderId: string,
  payAddress: string,
  payAmount: number
}> => {

  const response: AxiosResponse<{
    statusCode: number,
    data: {
      orderId: string,
      payAddress: string,
      payAmount: number
    }
  }> = await axios.post(`${getOrderServiceUrl(network)}/btc/${network}/orders/runes/etch/create`, json, {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${getAPIkey()}`
    }
  });

  if (response.data.statusCode === 0) {
    return response.data.data
  }

  throw new Error(`Failed to create mint order: ${response.data ? JSON.stringify(response.data) : "Unknow error."}`)
}



export const payEtchOrder = async (json: any, network: Network): Promise<{
  revealTx:{
    txId: string,
    status: string,
  },
  status: string
}> => {

  const response: AxiosResponse<{
    statusCode: number,
    data: {
      revealTx:{
        txId: string,
        status: string,
      },
      status: string
    }
  }> = await axios.post(`${getOrderServiceUrl(network)}/btc/${network}/orders/runes/etch/pay`, json, {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${getAPIkey()}`
    }
  });

  if (response.data.statusCode === 0) {
    return response.data.data
  }

  throw new Error(`Failed to pay etch order: ${response.data ? JSON.stringify(response.data) : "Unknow error."}`)
}

export const queryOrderInfo = async (orderId: string, network: Network): Promise<{
  desc: string,
  status: OrderStatus,
  payAmount: number,
  payAddress: string,
  postage: number,
  receiveAddress: string,
  feeRate: number,
  type: string,
  details: any
}> => {
  const response: AxiosResponse<{
    statusCode: number,
    data: {
      desc: string,
      status: OrderStatus,
      payAmount: number,
      payAddress: string,
      postage: number,
      receiveAddress: string,
      feeRate: number,
      type: string,
      details: any
    }
  }> = await axios.get(`${getOrderServiceUrl(network)}/btc/${network}/orders/${orderId}/info?details=1`, {
    headers: {
      'Content-Type': 'application/json',
      "Authorization": `Bearer ${getAPIkey()}`
    }
  });

  if (response.data.statusCode === 0) {
    return response.data.data
  }

  throw new Error(`Failed to queryOrderInfo status: ${JSON.stringify(response.data)}`)
}
