import { HistoryEntry, OrderStatus } from "./dtos/history.dto";
import { UtxoDto } from "./dtos/utxo.dto";
import { Network } from "./types";


export class StorageAPI {

    static getUTXO(txid: string, vout: number): UtxoDto {
        const key = `${txid}_${vout}`
        const item = localStorage.getItem(key)

        if (item == null) {
            throw new Error('UTXO not found')
        }

        return JSON.parse(item)
    }

    static storeUTXO(utxo: UtxoDto) {
        const key = `${utxo.txid}_${utxo.vout}`
        localStorage.setItem(key, JSON.stringify(utxo))
    }

    static confirmUTXO(
        txid: string, vout: number,
        block_hash: string,
        block_height: number,
        block_time: number
    ) {
        const utxo = StorageAPI.getUTXO(txid, vout)
        utxo.status = {
            block_hash,
            block_height,
            block_time,
            confirmed: true
        }

        this.storeUTXO(utxo)
    }

    static storeHistoryEntry(entry: HistoryEntry, network: Network) {
        let itemKey
        if (network === Network.Mainnet) {
            itemKey = 'runeHistory'
        } else {
            itemKey = `${network}_runeHistory`
        }
        const fetchedHistory = JSON.parse(localStorage.getItem(itemKey) || '[]');

        fetchedHistory.push({
            orderId: entry.orderId,
            runeName: entry.runeName,
            runeId: entry.runeId,
            dateTime: entry.dateTime,
            amount: entry.amount,
            action: entry.action,
            utxoTxId: entry.utxo.txid,
            utxoVout: entry.utxo.vout,
            batchMintStatus: entry.batchMintStatus
        })
        localStorage.setItem(itemKey, JSON.stringify(fetchedHistory))

        StorageAPI.storeUTXO(entry.utxo)
    }

    

    static setRuneId(orderId: string, runeId: string, network: Network) {
        let itemKey
        if (network === Network.Mainnet) {
            itemKey = 'runeHistory'
        } else {
            itemKey = `${network}_runeHistory`
        }
        const fetchedHistory = JSON.parse(localStorage.getItem(itemKey) || '[]');

        for (const entry of fetchedHistory) {
            if (entry.orderId === orderId) {
                entry.runeId = runeId
                localStorage.setItem(itemKey, JSON.stringify(fetchedHistory))
                return
            }
        }
        throw new Error(`No etch reveal transaction for orderId ${orderId}`)
    }

    static setOrderStatus(orderId: string, orderStatus: OrderStatus,  network: Network) {
        let itemKey
        if (network === Network.Mainnet) {
            itemKey = 'runeHistory'
        } else {
            itemKey = `${network}_runeHistory`
        }
        const fetchedHistory = JSON.parse(localStorage.getItem(itemKey) || '[]');

        for (const entry of fetchedHistory) {
            if (entry.orderId === orderId ) {
                entry.orderStatus = orderStatus
                localStorage.setItem(itemKey, JSON.stringify(fetchedHistory))
                return
            }
        }
        throw new Error(`No order found ${orderId}`)
    }

    static setBatchMintStatus(orderId: string, batchMintStatus: {
        repeat: number,
        confirmed: number,
        sent: number,
    }, network: Network) {
        let itemKey
        if (network === Network.Mainnet) {
            itemKey = 'runeHistory'
        } else {
            itemKey = `${network}_runeHistory`
        }
        const fetchedHistory = JSON.parse(localStorage.getItem(itemKey) || '[]');
        
        for (const entry of fetchedHistory) {
        
            if (entry.orderId === orderId) {
                entry.batchMintStatus = batchMintStatus
                localStorage.setItem(itemKey, JSON.stringify(fetchedHistory))
                return
            }
        }
        throw new Error(`No entry for orderTx ${orderId}`)
    }

    static getHistoryFull(network: Network): HistoryEntry[] {
        let itemKey
        if (network === Network.Mainnet) {
            itemKey = 'runeHistory'
        } else {
            itemKey = `${network}_runeHistory`
        }
        const fetchedHistory = JSON.parse(localStorage.getItem(itemKey) || '[]');

        const res: HistoryEntry[] = []


        const revealed: Set<string> = new Set()

        for (let i = fetchedHistory.length - 1; i >= 0; i--) {
            const entry = fetchedHistory[i]
            const utxo = StorageAPI.getUTXO(entry.utxoTxId, entry.utxoVout)

            if (entry.action === 0) {
                // Etch commit
                // only add if etch not revealed yet
                if (!revealed.has(entry.runeName)) {
                    res.push({
                        orderId: entry.orderId,
                        runeName: entry.runeName,
                        runeId: entry.runeId,
                        dateTime: entry.dateTime,
                        action: entry.action,
                        orderStatus: entry.orderStatus,
                        amount: 0,
                        utxo: utxo
                    })
                }
            } else if (entry.action === 1) {
                // Etch reveal
                revealed.add(entry.runeName)
                res.push({
                    orderId: entry.orderId,
                    runeName: entry.runeName,
                    runeId: entry.runeId,
                    dateTime: entry.dateTime,
                    action: entry.action,
                    orderStatus: entry.orderStatus,
                    amount: 0,
                    utxo: utxo
                })
            } else {
                // Mint
                res.push({
                    orderId: entry.orderId,
                    runeName: entry.runeName,
                    runeId: entry.runeId,
                    dateTime: entry.dateTime,
                    action: entry.action,
                    amount: entry.amount,
                    orderStatus: entry.orderStatus,
                    batchMintStatus: entry.batchMintStatus,
                    utxo: utxo
                })
            }

        }

        return res
    }

}