import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/system';

declare module "@mui/material/Button" {
  interface ButtonPropsSizeOverrides {
    fullWidth: true;
  }
}

export const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: "#FE9C2F",
      focus: "#FE9C2F",
    },
    secondary: {
      main: "#FE9C2F",
      focus: "#FE9C2F",
    },
    info: {
      main: "#11cdef",
      focus: "#11cdef",
    },
    success: {
      main: "#2dce89",
      focus: "#2dce89",
    },
    warning: {
      main: "#fb6340",
      focus: "#fb6340",
    },
    error: {
      main: "#f5365c",
      focus: "#f5365c",
    },
    background: {
      default: "#000",
    },
    card: {
      default: "#101010",  // Slightly lighter than the main background
    },
    text: {
      primary: "#fff",
      secondary: "#FE9C2F",
      disabled: "#BDBDBD",
      hint: "#9E9E9E"
    },
    grey: {
      100: "#f8f9fa",
      200: "#e9ecef",
      300: "#dee2e6",
      400: "#ced4da",
      500: "#adb5bd",
      600: "#6c757d",
      700: "#495057",
      800: "#343a40",
      900: "#212529",
    },
    common: {
      black: '#000',
      white: '#fff',
      red: '#f00',
    },
    action: {
      active: '#fff',
      hoverOpacity: 0.1,
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    pxToRem: (px: number) => `${px / 16}rem`,
  },
  shadows: [
    "none",
    ...Array.from({ length: 30 }, (_, i) => {
      const opacity = (i + 1) / 30;
      return `0px ${i}px ${i}px -${i / 2}px rgba(0,0,0,${opacity}), 0px ${i}px ${i}px 0px rgba(0,0,0,${opacity / 2}), 0px ${i}px ${i * 2}px 0px rgba(0,0,0,${opacity / 3})`;
    }),
  ],
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
    create: function (property = 'all', options: any = {}) {
      let { duration = '300ms', timingFunction = 'ease', delay = '0ms' } = options;
      return `${property} ${duration} ${timingFunction} ${delay}`;
    },
    getAutoHeightDuration: (height: number) => {
      if (!height) {
        return 0;
      }
      const constant = height / 36;
      return Math.round((4 + 15 * constant ** 0.25 + constant / 5) * 10);
    },
  },
  zIndex: {
    snarkbar: 1400,
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        size: 'large',
      },
      styleOverrides: {
        root: {
          fontWeight: '700',
          '&.Mui-disabled': {
            backgroundColor: '#999',
            color: '#FFF',
          },
        },
        contained: {
          color: '#000'
        }
      },
      variants: [
        {
          props: { size: "fullWidth" },
          style: { fontSize: 16, padding: "12px 0px", width: '100%' }
        }
      ]
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#999',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FE9C2F',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#FE9C2F',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        markLabel: {
          color: '#FE9C2F',
        },
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          zIndex: 'auto'
        }
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          color: '#FE9C2F',
        },
      }
    },
    MuiAlert: {
      styleOverrides: {
        filledSuccess: {
          backgroundColor: '#2dce89',
        },
        filledInfo: {
          backgroundColor: '#11cdef',
        },
        filledWarning: {
          backgroundColor: '#fb6340',
        },
        filledError: {
          backgroundColor: '#f5365c',
          // color: '#000',
        },
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: '#FE9C2F',
        },
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: 'linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 1) 100%)',
          zIndex: 1200,
        },
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: 10,
          paddingTop: 30,
          paddingBottom: 30,
          paddingLeft: 40,
          paddingRight: 40,
          backgroundColor: '#000',
          zIndex: 1200,
        },
      }
    },
  },
  mixins: {
    toolbar: {
      minHeight: 96,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 96,
      },
      '@media (min-width:600px)': {
        minHeight: 96,
      },
    },
  },
});