import { useEffect, useState } from "react";
import { Box, Typography, Slider, FormGroup, OutlinedInput, InputAdornment, Divider, FormLabel, FormHelperText } from '@mui/material';

import { useAppProvider } from "./AppContext";
import { DEFAULT_SERVICE_FEE, POSTAGE_SATS, sats2Btc, sats2Usd } from "./utils";

interface FeeProps {
  txSizeInBytes: number;
  txCnt?: number;
}

function Fee(props: FeeProps) {

  const {
    network,
    feeRateLevels: ctxFeeRateLevels,
    btcInUSD: ctxBtcInUSD,
    serviceFee,
    feeRate,
    setFeeRate,
  } = useAppProvider();

  const feeRateLevels = ctxFeeRateLevels || {
    minimumFee: 0,
    economyFee: 0,
    hourFee: 0,
    halfHourFee: 0,
    fastestFee: 0
  }

  const defaultFeeRate = feeRateLevels.fastestFee

  const btcInUSD = ctxBtcInUSD || 0

  const _feeRate = feeRate || defaultFeeRate

  const txCnt = props.txCnt || 1

  const networkFeeInSats = _feeRate * props.txSizeInBytes

  const serviceFeeInSats = (serviceFee?.fee || DEFAULT_SERVICE_FEE) * (txCnt > 25 ? 25 : txCnt)

  const totalFeeInSats = POSTAGE_SATS * txCnt + networkFeeInSats + serviceFeeInSats

  const onFeeRateChange = (e) => {
    if (e.target.value) {
      let val = parseInt(`${e.target.value}`)
      val = val < feeRateLevels.minimumFee ? feeRateLevels.minimumFee : val
      setFeeRate(val)
    } else {
      setFeeRate(defaultFeeRate)
    }
  }

  const getFeeRateLabel = (val: number) => {
    return val < feeRateLevels.hourFee ? 'Slow(>1 hour)'
      : val <= feeRateLevels.halfHourFee ? 'Normal(~30 to 60 mins)'
        : val < feeRateLevels.fastestFee ? 'Normal(~10 to 30 mins)'
          : 'Fast(~10 mins)'
  }


  return (
    <>
      <Divider sx={{ mt: 6, mb: 4 }} />
      <Box sx={{ mt: 2 }}>
        <Typography variant="h4" component="h4" gutterBottom>Fees</Typography>
      </Box>
      <FormGroup row sx={{ mt: 1, alignItems: 'center', }}>
        <FormLabel>Network Fee Rate: </FormLabel>
        <Slider
          aria-label="fee-rate-slider"
          valueLabelDisplay="auto"
          valueLabelFormat={getFeeRateLabel}
          min={feeRateLevels.economyFee}
          max={Math.floor(feeRateLevels.fastestFee / 0.618)}
          sx={{ display: 'flex' }}
          value={feeRate}
          onChange={onFeeRateChange}
          marks={feeRateLevels.fastestFee === 0 ? [] : [{ label: `Average(${feeRateLevels.halfHourFee} sats/vB) ~30 mins`, value: feeRateLevels.halfHourFee }]}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: 1 }}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <OutlinedInput
              id="fee-rate"
              endAdornment={<InputAdornment position="end">sats/vB</InputAdornment>}
              type="number"
              inputProps={{
                'aria-label': 'fee-rate',
              }}
              value={feeRate}
              onChange={onFeeRateChange}
            />
            <FormHelperText sx={{ display: 'flex', color: '#999', alignSelf: 'flex-end' }}>
              * Input a custom fee rate
            </FormHelperText>
          </Box>

        </Box>

      </FormGroup>
      <FormGroup row sx={{ mt: 2, alignItems: 'center', justifyContent: 'space-between' }}>
        <FormLabel>Network Fee: </FormLabel>
        <Typography>~{networkFeeInSats} sats&nbsp;&nbsp;{btcInUSD > 0 ? `~$${sats2Usd(networkFeeInSats, btcInUSD)}` : ''}</Typography>
      </FormGroup>
      <FormGroup row sx={{ mt: 2, alignItems: 'center', justifyContent: 'space-between' }}>
        <FormLabel>Sats in Rune: </FormLabel>
        <Typography>{txCnt > 1 ? `${txCnt} x ${POSTAGE_SATS} = ${txCnt * POSTAGE_SATS}` : POSTAGE_SATS} sats&nbsp;&nbsp;{btcInUSD > 0 ? `$${sats2Usd(POSTAGE_SATS, btcInUSD)}` : ''}</Typography>
      </FormGroup>
      <FormGroup row sx={{ mt: 2, alignItems: 'center', justifyContent: 'space-between' }}>
        <FormLabel>Service Fee: </FormLabel>
        <Typography>{serviceFeeInSats} sats&nbsp;&nbsp;{btcInUSD > 0 ? `$${sats2Usd(serviceFeeInSats, btcInUSD)}` : ''}</Typography>
      </FormGroup>
      <Divider sx={{ color: '#999', mt: 2 }} />
      <FormGroup row sx={{ mt: 2, mb: 2, alignItems: 'center', justifyContent: 'space-between' }}>
        <FormLabel>Total Fee: </FormLabel>
        <Typography>~{totalFeeInSats} sats&nbsp;&nbsp;{btcInUSD > 0 ? `~$${sats2Usd(totalFeeInSats, btcInUSD)}` : ''} </Typography>
      </FormGroup>
    </>
  )
}

export default Fee;