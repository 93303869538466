import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Button,
  TextField,
  FormGroup,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  Typography,
  CircularProgress,
} from "@mui/material";
import FormLabel from "@mui/material/FormLabel";

import ImageUploading, { ImageType } from "react-images-uploading";

import { useAppProvider } from "./AppContext";
import Fee from "./fee";
import {
  ETCH_COMMINT_TX_EST_SIZE,
  ETCH_REVEAL_TX_EST_SIZE,
  MINIMUM_RUNE_FOR_NEXT_BLOCK,
  POSTAGE_SATS,
  isValidRune,
  getExplorerServiceUrl,
  getMinimumRune,
  isRuneExist,
  createEtchOrder,
  payEtchOrder,
  queryOrderInfo,
} from "./utils";
import { Network, Rune } from "./types";
import { Notification } from "./components/Notification";
import { LinearProgressWithLabel } from "./components/LinearProgressWithLabel";
import { useInterval } from "usehooks-ts";
import { StorageAPI } from "./storage";
import { OrderStatus } from "./dtos/history.dto";


export enum TxStatus {
  PENDING = "pending",
  CONFIRMED = "confirmed",
  UNCONFIRMED = "unconfirmed",
  FAILED = "failed"
 }


function Etch() {
  const {
    network,
    serviceFee,
    feeRate,
    feeRateLevels,
    connectorRef,
    connectedWallet,
    connectedAccount,
  } = useAppProvider();

  const [_name, setName] = useState<string | undefined>(undefined);
  const [_divisibility, setDivisibility] = useState<number | undefined>(
    undefined
  );
  const [_symbol, setSymbol] = useState<string | undefined>(undefined);
  const [_premine, setPremine] = useState<number | undefined>(undefined);
  const [_amount, setAmount] = useState<number>(0);
  const [_cap, setCap] = useState<number>(0);

  const [_image, setImage] = useState<ImageType | undefined>(undefined);

  const [_startHeight, setStartHeight] = useState<number | undefined>();
  const [_endHeight, setEndHeight] = useState<number | undefined>();
  const [_startOffset, setStartOffset] = useState<number | undefined>();
  const [_endOffset, setEndOffset] = useState<number | undefined>();
  const [_open, setOpen] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<number>(0);
  const [isWaitingConfirm, setWaitingConfirm] = useState<boolean>(false);

  const [_order, setOrder] = useState<{
    orderId: string,
    txId: string,
    status: string
  } | null>(null);


  const [commitTxid, setCommitTxid] = useState<string | null>(null);

  const [revealTxid, setRevealTxid] = useState<string | null>(null);
  const [revealStatus, setRevealStatus] = useState<
    TxStatus
  >(TxStatus.PENDING);

  const [_notificationSeverity, setNotificationSeverity] = useState<
    "info" | "error" | "warning" | "success"
  >("info");
  const [_notificationMsg, setNotificationMsg] = useState<string | undefined>();

  const [_nameError, setNameError] = useState<string | undefined>();

  const [_minimumRune, setMinimumRune] = useState<string>(MINIMUM_RUNE_FOR_NEXT_BLOCK);


  const nameOnChange = (e) => {
    const input = e.target.value;
    let newValue = '';

    // Loop through each character in the input
    for (let i = 0; i < input.length; i++) {
      const char = input[i];
      if (/[a-zA-Z •\.]/.test(char)) { // Check if character is a letter or space
        const isSpacer = /[ \.]/.test(char)
        if (isSpacer && newValue.charAt(newValue.length - 1) === '•') {
          // Prevent consecutive spacers
          continue;
        } else {
          // Convert lowercase letters to uppercase and spaces to '•'
          newValue += isSpacer ? '•' : char.toUpperCase();
        }
      }
    }

    setName(newValue);
  };

  const imageOnChange = (imageList, addUpdateIndex) => {
    if (addUpdateIndex && addUpdateIndex.length) {
      setImage(imageList[addUpdateIndex[0]]);
    } else {
      setImage(imageList[0]);
    }
  };

  const divisibilityOnChange = (e) => {
    if (/^\d+$/.test(e.target.value)) {
      setDivisibility(parseInt(e.target.value));
    } else {
      setDivisibility(undefined);
    }
  };

  const premineOnChange = (e) => {
    if (/^\d+$/.test(e.target.value)) {
      const val = parseInt(e.target.value)
      if (val > 0) {
        setPremine(val);
      } else {
        setPremine(undefined);
      }
    } else {
      setPremine(undefined);
    }
  };


  const amountOnChange = (e) => {
    if (/^\d+$/.test(e.target.value)) {
      const val = parseInt(e.target.value)
      if (val > 0) {
        setAmount(val);
      } else {
        setAmount(0);
      }
    } else {
      setAmount(0);
    }
  };

  const capOnChange = (e) => {
    if (/^\d+$/.test(e.target.value)) {
      const val = parseInt(e.target.value)
      if (val > 0) {
        setCap(val);
      } else {
        setCap(0);
      }
    } else {
      setCap(0);
    }
  };

  const symbolOnChange = (e) => {
    const value = e.target.value
    if (value.length > 1) {
      return
    }

    const upperCase = value.toUpperCase() as string;

    if (upperCase) {
      setSymbol(upperCase.charAt(0));
    } else {
      setSymbol(undefined);
    }
  };

  const startHeightOnChange = (e) => {
    if (/^\d+$/.test(e.target.value)) {
      const val = parseInt(e.target.value)
      if (val > 0) {
        setStartHeight(val);
      } else {
        setStartHeight(undefined);
      }
    } else {
      setStartHeight(undefined);
    }
  };

  const endHeightOnChange = (e) => {
    if (/^\d+$/.test(e.target.value)) {
      const val = parseInt(e.target.value)
      if (val > 0) {
        setEndHeight(val);
      } else {
        setEndHeight(undefined);
      }
    } else {
      setEndHeight(undefined);
    }
  };

  const startOffsetOnChange = (e) => {
    if (/^\d+$/.test(e.target.value)) {
      const val = parseInt(e.target.value)
      if (val > 0) {
        setStartOffset(val);
      } else {
        setStartOffset(undefined);
      }
    } else {
      setStartOffset(undefined);
    }
  };

  const endOffsetOnChange = (e) => {
    if (/^\d+$/.test(e.target.value)) {
      const val = parseInt(e.target.value)
      if (val > 0) {
        setEndOffset(val);
      } else {
        setEndOffset(undefined);
      }
    } else {
      setEndOffset(undefined);
    }
  };

  const etchable = () => {
    const rune = getRune();

    if (typeof _nameError === 'string') {
      return false;
    }

    const isRuneValid = isValidRune(rune.name, _minimumRune);
    return (
      rune.name && isRuneValid && rune.amount > 0 && rune.cap > 0
    );
  };

  const getRune = () => {

    let dataURL;
    if (_image && _image.file) {
      dataURL = _image.data_url
    }

    const rune: Rune = {
      name: _name!,
      divisibility: _divisibility,
      symbol: _symbol,
      premine: _premine,
      amount: _amount!,
      cap: _cap!,
      startHeight: _startHeight,
      endHeight: _endHeight,
      startOffset: _startOffset,
      endOffset: _endOffset,
      dataURL
    };

    return rune;
  };

  const handleClose = (e) => {

    if (e.target.nodeName === 'BUTTON') {
      setOpen(false);
      setConfirmed(0);
      setWaitingConfirm(false);
    }
  };

  const startWaitConfirmed = () => {
    setConfirmed(0);
    setOpen(true);
    setWaitingConfirm(true);
  };

  const etch = async (e) => {
    e.preventDefault();

    if (!connectedWallet) {
      setNotificationSeverity("error");
      setNotificationMsg("Please connect wallet first!");
      return;
    }

    if (!connectedAccount || !connectorRef.current) {
      setNotificationSeverity("error");
      setNotificationMsg("No connected wallet account!");
      return;
    }

    if (!serviceFee || !feeRate) {
      setNotificationSeverity("error");
      setNotificationMsg("Fee options not set properly!");
      return;
    }

    if (!connectedAccount.payment.publicKey) {
      setNotificationSeverity("error");
      setNotificationMsg("No publicKey, try reconnect wallet!");
      return;
    }


    const rune = await isRuneExist(_name!, network);

    console.log('isRuneExist', rune)
    if (rune) {
      setNotificationSeverity("error");
      setNameError(`Rune with name "${_name}" already exists`)
      return;
    }

    const minimum = await getMinimumRune(network);

    const isValid = isValidRune(_name!, minimum);

    if (!isValid) {
      setNameError(`Name "${_name!}" is too short`)
      return;
    }

    async function impl() {
      if (!connectedAccount) { return }

      let ordAddress: string =
        connectedAccount.ordinals?.address! ||
        connectedAccount.payment.address


      const rune = getRune();
  
      
      const order = await createEtchOrder({
        "receiveAddress": ordAddress,
        "feeRate": feeRate,
        "postage": POSTAGE_SATS,
        "rune": rune
      }, network);

      


      let txid: string;
      try {
        console.log("sendBitcoin", order.payAddress,  order.payAmount, feeRate)
        txid = await connectorRef.current!.sendBitcoin(order.payAddress, order.payAmount, {
          feeRate: feeRate,
        });
      } catch (e) {
        setNotificationSeverity("error");
        setNotificationMsg((e as any).message ?? "sendBitcoin to commit address error!");
        console.log("sendBitcoin error", e);
        return;
      }

      setOrder({
        orderId: order.orderId,
        txId: txid,
        status: OrderStatus.PENDING,
      });

      console.log("txid:", txid);

      setCommitTxid(txid);

      StorageAPI.storeHistoryEntry({
        orderId: order.orderId,
        runeName: rune.name,
        orderStatus: OrderStatus.PENDING,
        runeId: '',
        dateTime: Math.floor(Date.now() / 1000),
        action: 0,
        amount: 0,
        utxo: {
          txid: txid,
          value: order.payAmount,
          vout: 0
        }
      }, network)


      startWaitConfirmed();
      const res = await payEtchOrder({
        orderId: order.orderId,
        txId: txid,
      }, network)


      StorageAPI.setOrderStatus(order.orderId, res.status as OrderStatus, network);
     
      setOrder({
        orderId: order.orderId,
        txId: txid,
        status: res.status as OrderStatus,
      });


      setRevealTxid(res.revealTx.txId);

      setRevealStatus(res.revealTx.status as TxStatus);

    }


    impl().catch(e => {
      setNotificationSeverity("error");
      setNotificationMsg(`Etching fail! error: ${e.message}`);
      console.error("Etching error", e);
    })
  };


  const runeNameOnBlur = () => {
    if (_name) {
      if (
        _name.startsWith('•') ||
        _name.endsWith('•') ||
        !/^[A-Z•]*$/.test(_name)
      ) {
        setNameError('Invalid Rune name')
      } else if (!isValidRune(_name, _minimumRune)) {
        setNameError(`Min ${_minimumRune.length} characters`)
      } else {
        setNameError(undefined)
        isRuneExist(_name, network).then((rune) => {
          if (rune) {
            setNameError(`Rune with name "${_name}" already exists`)
          }
        })
      }

    } else {

      setNameError(undefined)
    }
  }



  useInterval(
    () => {
      // Your custom logic here

      if (_order !== null) {
        queryOrderInfo(_order.orderId, network).then((orderInfo) => {

          console.log('orderInfo', orderInfo) 

          const {details} = orderInfo;
          if(details) {
            const {revealTx, confirmations} = details;
            setConfirmed(confirmations || 0);
            setRevealStatus(revealTx.status);
            if (revealTx.status === "confirmed" || revealTx.status === "failed") {
              setWaitingConfirm(false);
            }
          }
        }).catch(e => {
          console.error('queryToEtchStatus:', e)
        });
      }
    },
    // Delay in milliseconds or null to stop it
    isWaitingConfirm ? (network === Network.Mainnet ? 60*1000 : 5000) : null
  );

  useEffect(() => {
    getMinimumRune(network).then(minimumRune => {
      console.log('update minimumRune', minimumRune)
      setMinimumRune(minimumRune);
    })
  }, [network])

  const confirmedProgress = Math.min((confirmed / 6) * 100, 100);

  console.log('revealTxid', revealTxid)
  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 6 }}>
        <TextField
          label="Name "
          variant="outlined"
          fullWidth
          required
          placeholder="Uppercase letters"
          value={_name}
          onChange={nameOnChange}
          error={_nameError ? true : undefined}
          helperText={_nameError || "Enter space for '•'"}
          onBlur={runeNameOnBlur}
        />
        <TextField
          label="Amount"
          required
          sx={{ mt: 2 }}
          value={_amount || ''}
          variant="outlined"
          placeholder="Number of tokens minted in each mint transaction"
          fullWidth
          onChange={amountOnChange}
        />
        <TextField
          label="Cap"
          sx={{ mt: 2 }}
          required
          value={_cap || ''}
          variant="outlined"
          placeholder="Max number of mints"
          fullWidth
          onChange={capOnChange}
        />
        <FormGroup>
          <FormLabel
            sx={{ mt: 2, flexDirection: "column", display: "inline-flex" }}
          >
            Advanced
          </FormLabel>
          <TextField
            label="Divisibility"
            type="number"
            placeholder="Decimals, defaults to 0"
            InputProps={{ inputProps: { min: 0, max: 18 } }}
            variant="outlined"
            fullWidth
            sx={{ mt: 2 }}
            onChange={divisibilityOnChange}
            onBlur={(e) => {
              e.target.value = _divisibility?.toString() ?? "";
            }}
          />
          <TextField
            label="Symbol"
            variant="outlined"
            placeholder="Single character only, like $"
            fullWidth
            sx={{ mt: 2 }}
            onChange={symbolOnChange}
            value={_symbol}
            onBlur={(e) => {
              e.target.value = _symbol ?? "";
            }}
          />
          <TextField
            label="Premine"
            variant="outlined"
            value={_premine || ''}
            placeholder="Number of tokens pre-allocated to the etcher"
            fullWidth
            sx={{ mt: 2 }}
            onChange={premineOnChange}
          />
          <FormGroup row>
            <TextField
              label="Start Height"
              sx={{ mt: 2, width: "49%", marginRight: "2%" }}
              variant="outlined"
              value={_startHeight || ''}
              placeholder="Absolute block height at which minting can start"
              onChange={startHeightOnChange}
            />
            <TextField
              label="End Height"
              sx={{ mt: 2, width: "49%" }}
              variant="outlined"
              value={_endHeight || ''}
              placeholder="Absolute block height at which minting ends"
              onChange={endHeightOnChange}
            />
          </FormGroup>
          <FormGroup row>
            <TextField
              label="Start Offset"
              sx={{ mt: 2, width: "49%", marginRight: "2%" }}
              variant="outlined"
              value={_startOffset || ''}
              placeholder="Number of blocks relative to the etch block at which mint can start"
              onChange={startOffsetOnChange}
            />
            <TextField
              label="End Offset"
              sx={{ mt: 2, width: "49%" }}
              variant="outlined"
              value={_endOffset || ''}
              placeholder="Number of blocks relative to the etch block at which mint ends"
              onChange={endOffsetOnChange}
            />
          </FormGroup>
        </FormGroup>
        <FormGroup>
          <ImageUploading
            multiple={true}
            value={_image ? [_image] : []}
            onChange={imageOnChange}
            maxNumber={2}  // keep this so images can be swapped out 
            dataURLKey="data_url"
            acceptType={['jpg', 'jpeg', 'png']}
          >
            {({ onImageUpload, onImageRemoveAll, dragProps, imageList }) => (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', mt: 5 }}>
                <Button
                  variant="contained"
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  Add Logo
                </Button>
                {imageList.map((image, index) => (
                  <Box key={index} sx={{ mt: 2, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <img src={image['data_url']} alt="Logo" width="100%" />
                    <Button
                      sx={{ mt: 1, width: '50%', alignSelf: 'center' }} // Center the remove button below the image
                      onClick={() => onImageRemoveAll()}
                    >
                      Remove
                    </Button>
                  </Box>
                ))}
              </Box>
            )}
          </ImageUploading>
        </FormGroup>

        <Fee
          txSizeInBytes={ETCH_COMMINT_TX_EST_SIZE + ETCH_REVEAL_TX_EST_SIZE}
        />
        <Button
          variant="contained"
          size="fullWidth"
          color="primary"
          sx={{ mt: 2 }}
          disabled={!etchable()}
          onClick={etch}
        >
          Etch
        </Button>
        <Notification
          message={_notificationMsg}
          setMessage={setNotificationMsg}
          severity={_notificationSeverity}
        />

        <React.Fragment>
          <Dialog
            sx={{ zIndex: 1800 }}
            disableEscapeKeyDown={true}
            open={_open}
            fullWidth
            maxWidth="sm"
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Etching</DialogTitle>
            <DialogContent>
              {commitTxid !== null ? (
                <Box>
                  <Typography component={'span'} variant="h4">
                    Commit transaction:
                    <Link
                      href={`${getExplorerServiceUrl(network)}/tx/${commitTxid}`}
                      rel="noopener noreferrer"
                      target="_blank"
                      ml={1}
                      variant="body2"
                    >
                      {commitTxid?.substring(0, 4) +
                        "..." +
                        commitTxid?.substring(60)}
                    </Link>
                  </Typography>
                </Box>
              ) : (
                <></>
              )}

              {revealTxid !== null ? (
                <Box>
                  <Typography component={'span'} variant="h4">
                    Reveal transaction:

                    {
                      revealStatus === "pending" ? (
                        <CircularProgress size="1.2rem" sx={{
                          marginLeft: 1
                        }} />
                      ) : (
                        <Link
                          href={`${getExplorerServiceUrl(network)}/tx/${revealTxid}`}
                          rel="noopener noreferrer"
                          target="_blank"
                          ml={1}
                          variant="body2"
                        >
                          {revealTxid?.substring(0, 4) +
                            "..." +
                            revealTxid?.substring(60)}
                        </Link>
                      )
                    }
                  </Typography>
                </Box>
              ) : (
                <></>
              )}

              {revealStatus === "pending" ? (
                <Box>
                  <DialogContentText id="alert-dialog-description" mt={1}>
                    After the commit transaction matures, the reveal will take
                    place. (still need to wait for
                      <Chip
                        component={"span"}
                        label={Math.max(6 - confirmed, 0)}
                        size="small"
                      />
                    blocks).
                  </DialogContentText>
                  <LinearProgressWithLabel value={confirmedProgress} />
                </Box>
              ) : (
                <></>
              )}

              {revealStatus === "unconfirmed" ? (
                <Box>
                  <DialogContentText id="alert-dialog-description" mt={1}>
                    Wait reveal transaction to be confirmed <CircularProgress size="1rem" sx={{ markerStart: 1 }} />
                  </DialogContentText>

                </Box>
              ) : (
                <></>
              )}

              {revealStatus === "confirmed" ? (
                <Box>
                  <DialogContentText id="alert-dialog-description" mt={1}>
                    Etching is succeed
                  </DialogContentText>
                </Box>
              ) : (
                <></>
              )}

              {revealStatus === "failed" ? (
                <Box>
                  <DialogContentText id="alert-dialog-description" mt={1}>
                    Reveal transaction broadcast failed!
                  </DialogContentText>
                </Box>
              ) : (
                <></>
              )}

              <DialogContentText id="alert-dialog-description" color="grey" mt={1}>
                You can see progress in history.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </React.Fragment>
      </Box>
    </Container>
  );
}

export default Etch;
