import { UtxoDto } from "./utxo.dto";

export enum OrderStatus {
  PENDING = "pending",
  PROCESSING = "processing",
  SUCCEEDED = "succeeded",
  FAILED= "failed",
 }




export interface HistoryEntry {
  orderId: string;
  runeName: string;
  runeId: string;
  dateTime: number;
  action: number;  // 0 - etch commit, 1 - etch reveal, 2 - mint
  amount: number;  // amount minted
  orderStatus: OrderStatus,
  batchMintStatus?: {
    repeat: number,
    sent: number,
    confirmed: number
  }
  utxo: UtxoDto;
}