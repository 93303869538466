import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Box, Button, Container, Typography, CssBaseline, Tab, Tabs, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/system';
import { useAppProvider } from "./AppContext";
import { useEffect, useState } from "react";
import Mint from "./mint";
import Etch from "./etch";
import Transfer from "./transfer";
import History from "./history";
import { theme } from "./theme";
import NavBar from "./components/NavBar";
import { useLocation } from 'react-router-dom';
import { Network } from "./types";

function Home() {

  const [_tabIndex, setTabIndex] = useState(0);

  const tabOnChange = (e: any, tabIndex: number) => {
    // if (tabIndex === 0) {
    //   <>OrdinalImage</>
    // } else if (tabIndex === 1) {
    //   <>OrdinalText</>
    // } else if (tabIndex === 2) {
    //   <>BSV20v1</>
    // } else if (tabIndex === 3) {
    //   <>BSV20v2</>
    // }
    setTabIndex(tabIndex);
  };

  useEffect(() => {
  }, [])

  return (
    <>
      <NavBar />
      <Box sx={{ width: '100%', mb: 5 }}>
        <Typography variant="h1" component="h1" gutterBottom align="center">RUNES</Typography>
      </Box>
      <Box sx={{ width: '100%', mb: 5 }}>
        <Grid container justifyContent="center">
          <Tabs value={_tabIndex} onChange={tabOnChange}>
            <Tab label={(<Typography variant="h2">Mint</Typography>)} sx={{ pl: 10, pr: 10 }} />
            <Tab label={(<Typography variant="h2">Etch</Typography>)} sx={{ pl: 10, pr: 10 }} />
            <Tab label={(<Typography variant="h2">History</Typography>)} sx={{ pl: 10, pr: 10 }} />
            {/* <Tab label={(<Typography variant="h2">Transfer</Typography>)} sx={{ pl: 10, pr: 10 }} /> */}
          </Tabs>
        </Grid>
        {_tabIndex === 0 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <Mint />
          </Box>
        )}
        {_tabIndex === 1 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <Etch />
          </Box>
        )}
        {_tabIndex === 2 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <History />
          </Box>
        )}
        {/* {connected() && _tabIndex === 3 && (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
            <Transfer />
          </Box>
        )} */}
      </Box>
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;
