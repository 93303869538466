import React, {
  createContext,
  useEffect,
  useState,
  useRef,
  useContext,
} from "react";

import axios from "axios";
import { AxiosResponse } from "axios";
import { ServiceFee, getBackendServiceUrl, getServiceFee } from "./utils";
import { WalletConnector, Network, ConnectedAccount } from "./types";

export interface FeeRateLevels {
  minimumFee: number;
  economyFee: number;
  hourFee: number;
  halfHourFee: number;
  fastestFee: number;
}

interface ChainStats {
  blockNumber: number;
  btcInUSD: number;
  feeRateLevels: FeeRateLevels;
}

export interface AppContextProps extends Partial<ChainStats> {
  network: Network;
  setNetwork: React.Dispatch<React.SetStateAction<Network>>;

  connectedWallet?: string
  setConnectedWallet: React.Dispatch<React.SetStateAction<string | undefined>>;

  connectedAccount?: ConnectedAccount;
  setConnectedAccount: React.Dispatch<React.SetStateAction<ConnectedAccount | undefined>>;

  connectorRef: React.RefObject<WalletConnector>;

  feeRate?: number;
  setFeeRate: React.Dispatch<React.SetStateAction<number | undefined>>;

  serviceFee?: ServiceFee;
}

export const AppContext = createContext<AppContextProps | undefined>(undefined);

interface AppProviderProps {
  children: React.ReactNode;
}

export const AppProvider = (props: AppProviderProps) => {
  const { children } = props;

  const connectorRef = useRef<WalletConnector>(null);

  const networkInUrl = new URLSearchParams(window.location.search).get('network');
  // TODO: switch default to Mainnet after runes launching
  const [network, setNetwork] = useState<Network>(networkInUrl === 'testnet' ? Network.Testnet : Network.Mainnet);

  const [chainStats, setChainStats] = useState<ChainStats | undefined>()
  const [connectedWallet, setConnectedWallet] = useState<string | undefined>();
  const [connectedAccount, setConnectedAccount] = useState<ConnectedAccount | undefined>();
  const [feeRate, setFeeRate] = useState<number | undefined>();
  const [serviceFee, setServiceFee] = useState<ServiceFee | undefined>();

  useEffect(() => {
    axios
      .get<any, AxiosResponse<ChainStats, any>>(
        `${getBackendServiceUrl(network)}/runes/chain-stats/${network.toLowerCase()}`,
      )
      .then((resp) => {
        if (resp.status === 200) {
          console.log('chainStats', resp.data)
          setChainStats(resp.data)
          setFeeRate(resp.data.feeRateLevels.fastestFee)
        }
      })
      .catch((e) => {
        console.error(e)
      });

      getServiceFee(network).then((serviceFee) => {
        setServiceFee(serviceFee)
      })
  }, [network]);

  return (
    <AppContext.Provider
      value={
        {
          ...chainStats,
          network,
          setNetwork,
          connectedAccount,
          setConnectedAccount,
          connectedWallet,
          setConnectedWallet,
          feeRate,
          setFeeRate,
          serviceFee,
          connectorRef,
        }
      }
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppProvider = (): AppContextProps => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppProvider must be used within an AppProvider");
  }
  return context;
};
