import { AppBar, Box, Toolbar, Divider } from '@mui/material';
import Connector from './Connector';
import { useAppProvider } from '../AppContext';
import { Network } from '../types';
import ScienceIcon from '@mui/icons-material/Science';

function NavBar() {

  const {
    network,
    connectorRef
  } = useAppProvider();

  return (
    <AppBar
      position="sticky"
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <img src="/logo.png" alt="sCrypt Logo" style={{ height: '50px' }} />
        </Box>
        {
          network === Network.Testnet && (
          <Box sx={{display: 'flex', mr: 2, alignItems: 'center'}}>
            <ScienceIcon/>
            <span>Testnet</span>
          </Box>
          )
        }
        <Box>
          <Connector ref={connectorRef}/>
        </Box>
      </Toolbar>
      {/* <Divider sx={{ color: '#999'}}/> */}
    </AppBar>
  )
}

export default NavBar;