import { Box, Typography, Slider, FormGroup, OutlinedInput, InputAdornment, FormHelperText } from '@mui/material';

interface BatchProps {
  onChange: (n: number) => void;
  repeat: number
}

function Batch(props: BatchProps) {


  const onRepeatChange = (e) => {
    if (e.target.value) {
      let val = parseInt(`${e.target.value}`)
      props.onChange(val)
    } else {
      props.onChange(25)
    }
  }



  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography variant="h4" component="h4" gutterBottom>Batch mint:</Typography>
      </Box>
      <FormGroup row sx={{ mt: 1, alignItems: 'center', }}>
        <Slider
          aria-label="fee-rate-slider"
          valueLabelDisplay="auto"
          min={1}
          max={1000}
          sx={{ display: 'flex' }}
          value={props.repeat}
          onChange={onRepeatChange}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: 1 }}>
          <Box sx={{display: 'flex', flexDirection: 'column'}}>
            <OutlinedInput
              id="repeat"
              endAdornment={<InputAdornment position="end">repeat</InputAdornment>}
              type="number"
              inputProps={{
                'aria-label': 'fee-rate',
              }}
              value={props.repeat}
              onChange={onRepeatChange}
            />
            <FormHelperText sx={{ display: 'flex', color: '#999', alignSelf: 'flex-end' }}>
              * Input a repeat count
            </FormHelperText>
          </Box>

        </Box>

      </FormGroup>
   
    </>
  )
}

export default Batch;