import axios from "axios";
import { AxiosResponse } from "axios";
import * as bitcoin from "bitcoinjs-lib";
import React, { useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  TextField,
  InputAdornment,
  Card,
  CardContent,
  CardMedia,
  Link,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Chip,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import SearchIcon from "@mui/icons-material/Search";

import {
  POSTAGE_SATS,
  formatDate,
  isValidBTCAddress,
  getIndexerServiceUrl,
  calcTotalSize,
  queryOrderInfo,
  getExplorerServiceUrl,
  createMintOrder,
  payMintOrder,
} from "./utils";
import { Notification } from "./components/Notification";
import { GetRuneResponseDto } from "./dtos/getRuneResponse.dto";
import { Network } from "./types";
import { useAppProvider } from "./AppContext";
import Fee from "./fee";


import { RuneId, Runestone, none, some } from "runelib";
import { StorageAPI } from "./storage";
import Batch from "./batch";
import { LinearProgressWithLabel } from "./components/LinearProgressWithLabel";
import { useInterval } from "usehooks-ts";
import { OrderStatus } from "./dtos/history.dto";

function RuneProperty(props: { name: string; value: any }) {
  return (
    <Typography variant="body1" sx={{ mt: 2, ml: 2 }}>
      <span style={{ color: "#FE9C2F", marginRight: "12px" }}>
        {props.name}:
      </span>
      {props.value}
    </Typography>
  );
}

function Mint() {
  const [_rune, setRune] = useState<GetRuneResponseDto | undefined>();
  const [_runeName, setRuneName] = useState<string | undefined>(undefined);
  const [_runeNameErr, setRuneNameErr] = useState<undefined | string>();

  const [_isLoading, setLoading] = useState<boolean>(false);

  const [_notificationSeverity, setNotificationSeverity] = useState<
    "info" | "error" | "warning" | "success"
  >("info");
  const [_notificationMsg, setNotificationMsg] = useState<string | undefined>();
  const [_repeat, setRepeat] = useState<number>(25);
  const [_totalSize, setTotalSize] = useState<number>(25);
  const [_open, setOpen] = useState<boolean>(false);
  const [_confirmedTxCount, setConfirmedTxCount] = useState<number>(0);
  const [_sentTxCount, setSentTxCount] = useState<number>(0);
  const [_isWaitingConfirm, setWaitingConfirm] = useState<boolean>(false);
  const [_order, setOrder] = useState<{
    orderId: string,
    txId: string,
    status: string
  } | null>(null);

  const handleClose = (e) => {
    if (e.target.nodeName === "BUTTON") {
      setOpen(false);
      setConfirmedTxCount(0);
      setWaitingConfirm(false);
    }
  };

  const startWaitConfirmed = () => {
    setConfirmedTxCount(0);
    setOpen(true);
    setWaitingConfirm(true);
  };

  const {
    network,
    serviceFee,
    feeRate,
    connectorRef,
    connectedWallet,
    connectedAccount,
  } = useAppProvider();

  const runeNameOnChange = (e) => {
    const input = e.target.value;
    let newValue = "";

    // Loop through each character in the input
    for (let i = 0; i < input.length; i++) {
      const char = input[i];
      if (/[a-zA-Z •\.]/.test(char)) {
        // Check if character is a letter or space
        const isSpacer = /[ \.]/.test(char);
        if (isSpacer && newValue.charAt(newValue.length - 1) === "•") {
          // Prevent consecutive spacers
          continue;
        } else {
          // Convert lowercase letters to uppercase and spaces to '•'
          newValue += isSpacer ? "•" : char.toUpperCase();
        }
      }
    }

    setRuneName(newValue);
  };

  const addrOnChange = (e) => {
    const input = e.target.value;
    setReceiverAddr(input.replace(/\s+/g, "")); // String whitespaces
  };

  const searchRune = () => {
    if (!_runeName) return;
    setLoading(true);
    axios
      .get<any, AxiosResponse<GetRuneResponseDto, any>>(
        `${getIndexerServiceUrl(network)}/rune/${_runeName}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((resp) => {
        if (resp.status === 200) {
          setRune(resp.data);
          updateFee();
        }
      })
      .catch((e) => {
        console.error("searchRune error", e)
        if (e.response.status === 404 || e.response.status === 400) {
          setRuneNameErr(`Rune with name "${_runeName}" not found`);
        } else {
          setNotificationSeverity("error");
          setNotificationMsg(e.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const runeNameOnKeyDown = (e) => {
    if (e.key === "Enter" && _runeName) {
      searchRune();
    }
  };

  const updateFee = () => {
    if (_rune) {
      const idx = parseInt(_rune.id.split(":")[1]);
      const mintstone = new Runestone(
        [],
        none(),
        some(new RuneId(_rune.entry.block, idx)),
        none()
      );

      const mintstoneScript = mintstone.encipher();
      const { totalSize } = calcTotalSize(_repeat, mintstoneScript.length);
      setTotalSize(totalSize);
    } else {
      const mintstone = new Runestone(
        [],
        none(),
        some(new RuneId(840000, 10000000)),
        none()
      );

      const mintstoneScript = mintstone.encipher();
      const { totalSize } = calcTotalSize(_repeat, mintstoneScript.length);
      setTotalSize(totalSize);
    }
  };

  const onRepeatChange = (repeat) => {
    setRepeat(repeat);
    updateFee();
  };

  const isMintable = () => {
    return (
      connectedAccount !== undefined &&
      connectorRef.current &&
      _rune?.mintable &&
      !_receiverAddrErr
    );
  };


  const batchMint = async () => {
    try {
      if (!connectedAccount || !connectorRef.current) {
        throw new Error("No connected wallet account");
      }

      if (!_rune) {
        throw new Error("No Rune selected");
      }

      if (!_receiverAddr || !isValidBTCAddress(_receiverAddr, network)) {
        throw new Error(`Invalid receiver address: ${_receiverAddr}`);
      }

      if (!serviceFee || !feeRate) {
        throw new Error(
          `Fee options not set properly! feeRate: ${feeRate}, serviceFee: ${JSON.stringify(
            serviceFee
          )}`
        );
      }


      const order = await createMintOrder({
        "receiveAddress": _receiverAddr,
        "feeRate": feeRate,
        "postage": POSTAGE_SATS,
        "runeNameOrId": _rune.id,
        "repeat": _repeat
      }, network)


      const orderTxId = await connectorRef.current.sendBitcoin(
        order.payAddress,
        order.payAmount,
        { feeRate: feeRate }
      );


      setOrder({
        orderId: order.orderId,
        txId: orderTxId,
        status: OrderStatus.PENDING,
      });

      
      StorageAPI.storeHistoryEntry({
        orderId: order.orderId,
        runeName: _rune.entry.spaced_rune,
        runeId: _rune.id,
        dateTime: Math.floor(Date.now() / 1000),
        action: 3,
        amount: _rune.entry.terms?.amount || 0,
        orderStatus: OrderStatus.PENDING,
        batchMintStatus: {
          repeat: _repeat,
          sent: _sentTxCount,
          confirmed: _confirmedTxCount
        },
        utxo: {
          txid: orderTxId!,
          value: order.payAmount,
          vout: 0,
        },
      }, network);

      startWaitConfirmed();
      const res = await payMintOrder({
        orderId: order.orderId,
        txId: orderTxId,
      }, network)



      StorageAPI.setOrderStatus(order.orderId, res.status as OrderStatus, network);
     
      setOrder({
        orderId: order.orderId,
        txId: orderTxId,
        status: res.status as OrderStatus,
      });
      
    } catch (error: any) {
      setNotificationMsg(`${error.message}`);
      setNotificationSeverity("error");
    }
  };

  const formatQuantity = (amount: number, symbol): string => {
    return `${amount} ${symbol === null ? "¤" : symbol}`;
  };

  const [_loadedRuneImg, setLoadedRuneImg] = useState<string | undefined>();
  const startLoadingRuneImg = (name: string) => {
    setLoadedRuneImg(name);
  };

  const [_receiverAddr, setReceiverAddr] = useState<string | undefined>();
  const [_receiverAddrErr, setReceiverAddrErr] = useState<string | undefined>();

  const validateReciverAddress = () => {
    if (_receiverAddr && !isValidBTCAddress(_receiverAddr, network)) {
      setReceiverAddrErr(`Invalid ${network} address`);
    } else {
      setReceiverAddrErr(undefined);
    }
  };

  const runeNameOnBlur = () => {
    if (
      _runeName &&
      (_runeName.startsWith("•") ||
        _runeName.endsWith("•") ||
        !/^[A-Z•]*$/.test(_runeName))
    ) {
      setRuneNameErr("Invalid rune name");
    } else {
      setRuneNameErr(undefined);

      if (_runeName) {
        searchRune();
      }
    }
  };

  useEffect(() => {
    if (connectedAccount) {
      setReceiverAddr(
        connectedAccount?.ordinals?.address || connectedAccount?.payment.address
      );
    } else {
      setReceiverAddr(undefined);
    }
  }, [connectedAccount]);

  useInterval(
    () => {
      // Your custom logic here

      if (_order !== null) {
        queryOrderInfo(_order.orderId, network)
          .then((orderInfo) => {

            console.log('orderInfo', orderInfo) 

            const {details} = orderInfo;

            if(details) {

              const {txs} = details;

              const confirmed = txs.reduce((acc, tx) => {

                if(tx.status === "confirmed") {
                  acc++;
                }
                return acc;
              }, 0)

              const sent = txs.reduce((acc, tx) => {

                if(tx.status === "confirmed" || tx.status === "unconfirmed") {
                  acc++;
                }
                return acc;
              }, 0)

              setConfirmedTxCount(confirmed);
              setSentTxCount(sent);
            }

     
          })
          .catch((e) => {
            console.error("queryOrderInfo:", e);
          });
      }
    },
    // Delay in milliseconds or null to stop it
    _isWaitingConfirm
      ? network === Network.Mainnet
        ? 60 * 1000
        : 5 * 1000
      : null
  );

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 6 }}>
        <TextField
          label="Name"
          variant="outlined"
          placeholder="Ticker"
          fullWidth
          required
          error={typeof _runeNameErr === "string"}
          helperText={_runeNameErr || "Enter space for '•'"}
          value={_runeName}
          onChange={runeNameOnChange}
          onKeyDown={runeNameOnKeyDown}
          onFocus={() => setRuneNameErr(undefined)}
          onBlur={runeNameOnBlur}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={searchRune}
                sx={{ cursor: "pointer", p: 3 }}
              >
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label="Receiver Address"
          required
          variant="outlined"
          placeholder={`Address to receive the minted tokens`}
          fullWidth
          error={typeof _receiverAddrErr === "string"}
          helperText={_receiverAddrErr || ""}
          sx={{ mt: 2 }}
          onChange={addrOnChange}
          onBlur={validateReciverAddress}
          value={_receiverAddr || ""}
        />
        {_rune && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Card
              sx={{ display: "flex", mt: 6, p: 2, justifyContent: "center" }}
            >
              {_rune.parent && (
                <>
                  <Box
                    sx={{
                      display:
                        _loadedRuneImg === _rune.entry.spaced_rune
                          ? "inherit"
                          : "none",
                    }}
                  >
                    <CardMedia
                      component="iframe"
                      sx={{ border: "none" }}
                      src={`https://${
                        network === Network.Testnet ? `${network}.` : ""
                      }ordinals.com/preview/${_rune.parent}`}
                      onLoad={() =>
                        startLoadingRuneImg(_rune.entry.spaced_rune)
                      }
                    />
                  </Box>
                  <CircularProgress
                    color="inherit"
                    sx={{
                      display:
                        _loadedRuneImg !== _rune.entry.spaced_rune
                          ? "inherit"
                          : "none",
                      mt: "25%",
                      ml: 15,
                      mr: 15,
                    }}
                  />
                </>
              )}
              <CardContent>
                <RuneProperty name="Id" value={_rune.id} />
                <RuneProperty
                  name="Timestamp"
                  value={formatDate(new Date(_rune.entry.timestamp * 1000))}
                />
                <Typography variant="body1" sx={{ mt: 2, ml: 2 }}>
                  <span style={{ color: "#FE9C2F", marginRight: "12px" }}>
                    Name:
                  </span>
                  <Link
                    target="_blank"
                    href={`https://${
                      network === Network.Testnet ? "testnet." : ""
                    }ordinals.com/rune/${_rune.entry.spaced_rune}`}
                  >
                    {_rune.entry.spaced_rune}
                  </Link>
                </Typography>
                <RuneProperty
                  name="Divisibility"
                  value={_rune.entry.divisibility}
                ></RuneProperty>
                <RuneProperty
                  name="Supply"
                  value={formatQuantity(
                    _rune.entry.mints * (_rune.entry.terms?.amount || 0) +
                      _rune.entry.premine,
                    _rune.entry.symbol
                  )}
                ></RuneProperty>
                <RuneProperty
                  name="Premine"
                  value={formatQuantity(
                    _rune.entry.premine,
                    _rune.entry.symbol
                  )}
                ></RuneProperty>
                <RuneProperty
                  name="Amount"
                  value={formatQuantity(
                    _rune.entry.terms?.amount || 0,
                    _rune.entry.symbol
                  )}
                ></RuneProperty>
                <RuneProperty
                  name="Cap"
                  value={_rune.entry.terms?.cap || 0}
                ></RuneProperty>
                <RuneProperty
                  name="Mints"
                  value={_rune.entry.mints || 0}
                ></RuneProperty>
                <RuneProperty
                  name="Mintable"
                  value={_rune.mintable ? "Yes" : "No"}
                ></RuneProperty>
              </CardContent>
            </Card>
          </Box>
        )}
        <Notification
          message={_notificationMsg}
          setMessage={setNotificationMsg}
          severity={_notificationSeverity}
        />
      </Box>
      <Batch onChange={onRepeatChange} repeat={_repeat}></Batch>
      <Fee txSizeInBytes={_totalSize} txCnt={_repeat} />
      <Button
        variant="contained"
        size="fullWidth"
        color="primary"
        sx={{ mt: 2 }}
        disabled={!connectedWallet || !isMintable()}
        onClick={batchMint}
      >
        Batch Mint
      </Button>

      <React.Fragment>
        <Dialog
          sx={{ zIndex: 1800 }}
          disableEscapeKeyDown={true}
          open={_open}
          fullWidth
          maxWidth="sm"
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Minting</DialogTitle>
          <DialogContent>
            {_order !== null ? (
              <Box>
                <Typography component={"span"} variant="h4">
                  Order Id: {_order.orderId}
                  <Link
                    href={`${getExplorerServiceUrl(network)}/tx/${_order.txId}`}
                    rel="noopener noreferrer"
                    target="_blank"
                    ml={1}
                    variant="body2"
                  >
                    {_order.txId?.substring(0, 4) +
                      "..." +
                      _order.txId?.substring(60)}
                  </Link>
                </Typography>
              </Box>
            ) : (
              <></>
            )}

            <Box>
              <DialogContentText id="alert-dialog-description" mt={1}>
                waiting for confirmation...
              </DialogContentText>
              <Box mt={1}>
                <DialogContentText>
                  Total mint:
                  <Chip component={"span"} label={_repeat}></Chip>
                </DialogContentText>
              </Box>

              <Box>
                <DialogContentText variant="h4">
                  Total sent:
                  <Chip component={"span"} label={_sentTxCount}></Chip>
                </DialogContentText>
              </Box>

              <Box>
                <DialogContentText variant="h4">
                  Total confirmed:
                  <Chip component={"span"} label={_confirmedTxCount}></Chip>
                </DialogContentText>
              </Box>
              <LinearProgressWithLabel
                value={Math.ceil((_confirmedTxCount * 100) / _repeat)}
              />
            </Box>

            <DialogContentText
              id="alert-dialog-description"
              color="grey"
              mt={1}
            >
              You can see progress in history.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <Backdrop sx={{ color: "#fff", zIndex: 1200 }} open={_isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

export default Mint;
